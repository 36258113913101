




import LabGrahamsLaw from '../simulations/LabGrahamsLaw.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';

export default defineComponent({
  name: 'GrahamsLawSIM',
  components: {LabGrahamsLaw, LabTask},
  mixins: [DynamicQuestionMixin()],
});
