import { render, staticRenderFns } from "./LabGrahamsLaw.vue?vue&type=template&id=4ae5f6df&"
import script from "./LabGrahamsLaw.vue?vue&type=script&lang=ts&"
export * from "./LabGrahamsLaw.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports